div.custom-container {
	display: block;
	background-color: #000011;
	justify-content: center;
	overflow: visible;

}
.Alerts-anomalyEntryBox2-195 {
	width: 50%;
	    color: aqua;
	    display: flex;
	    align-items: center;
	    border-style: solid;
	    border-width: 1px;
	    border-color: cornflowerblue;
	    justify-content: center;

}

svg.custom {
	overflow: overlay;
	margin-top: 5em;
}
/* .MuiInputBase-input-391 Alerts-input-223{
	-webkit-animation: noflashtext linear 2s infinite!important;
	animation: noflashtext linear 2s infinite!important;
} */

 /* .flashingred {
	background-color: #000011;
	-webkit-animation: flash linear 2s infinite;
	animation: flash linear 2s infinite;
} */
 /* @-webkit-keyframes flash {
	0% { opacity: 1; }
	50% { opacity: .1; }
	100% { opacity: 1; }
} */
/* @keyframes flash {
	0% { opacity: 1; }
	50% { opacity: .1; }
	100% { opacity: 1; }
} */

/* @-webkit-keyframes noflashtext {
	0% { opacity: 1; }
	50% { opacity: 1; }
	100% { opacity: 1; }
} */
/* @keyframes noflashtext {
	0% { opacity: 1; }
	50% { opacity: 1; }
	100% { opacity: 1; }
} */

 .node circle {
	fill: rgb(0, 0, 17);
	stroke: rgb(100, 149, 237);
	stroke-width: 2px;
}
.nodemobile circle {
 fill: rgb(0, 0, 17);
 stroke: rgb(100, 149, 237);
 stroke-width: 2px;
}
.material-icons-round.md-36 {
	font-size: 36px;
	color: rgba(100, 149, 237, 1);
	width: 36px;
}

.material-icons-round.md-36mobie {
	font-size: 2em;
	color: rgba(100, 149, 237, 1);
	width: 36px;
}

#trashcan {
	font-size: 36px;
	color: #F44336;
	width: 36px;
}
#edit {
	font-size: 36px;
	color: rgb(88, 255, 86);
	width: 36px;
}
#editsmall {
	font-size: 2em;
	color: rgb(88, 255, 86);
	width: 36px;

}

#seeasnormie {
	font-size: 2em;
	color: rgb(76, 254, 248, 1);
	width: 36px;

}

.red-node {
	fill: red;    font-size: 36px;
    color: #F44336;
    width: 36px;
	stroke: red;
}

.selectedpath {
	fill: none;
	stroke: red;
	stroke-width: 1.5px;
}

.node text {
	font-size: 12px;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	background-color: grey;
	fill: grey;
	font-weight: bold;
}

.node {
	cursor: pointer;
}
.nodemobile {
	cursor: pointer;
}
.nodemobile text {
	font-size: 12px;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	background-color: grey;
	fill: grey;
	font-weight: bold;
}

path.link {
	fill: none;
	stroke: cornflowerblue;
	stroke-width: 1.5px;
}


span {
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	/* float: right; */
}

/* experiments with .preview {
  padding: 1rem;
  margin-top: 1rem;
} for the rich text editor.*/
.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
	margin-bottom: 50%;
}
.editor-class {
  background-color:lightgray;
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
}

.preview {
	background-color: white;
  padding: 1rem;
  margin-top: 1rem;
}

/* experiments with docs, extra examples at https://github.com/jpuri/react-draft-wysiwyg/tree/master/stories */

.rdw-storybook-root {
	background-color: white;
	margin-bottom: -5Spx;
}
.rdw-storybook-wrapper {
}
.rdw-storybook-editor {
  border: 1px solid #F1F1F1;
  padding: 5px;
  border-radius: 2px;
  height: 200px;
}
.rdw-storybook-toolbar {
  top: -130px;
}
.rdw-storybook-textarea {
  resize: none;
  width: 100%;
  border: 1px solid #F1F1F1;
  height: 100px;
}
figure {
  margin: 0px;
}
