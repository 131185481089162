.parent{
 position : relative;
 min-height: 400px;
}
.parent .chart{
    position: absolute;
    height: 400px;
    width: 100%;
    z-index: 100;
}
table, td, th {
  border: 1px solid black;
  border-color: #6495ed;
  color: #adadad;
  padding-right: 30px;
  padding-bottom: 30px;
  padding-top: 30px;
  padding-left: 30px;
  text-align: center;
}

table {
  border-collapse: collapse;
  margin-bottom: 30px;
  margin-top: 30px;
  border-radius: 5px;
}
